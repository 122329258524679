import styles from './Hamburger.module.css';

type Props = {
    isOpen: boolean;
}

const Hamburger = (props: Props) => {
    return (
        <>
            <div className={styles.hamburger}>
                <div className={`${styles.burger}`} style={{ transform: props.isOpen ? 'rotate(45deg)' : 'rotate(0)' }} />
                <div className={`${styles.burger}`} style={{ transform: props.isOpen ? 'translateX(100%)' : 'translateX(0)', opacity: props.isOpen ? 0 : 1 }} />
                <div className={`${styles.burger}`} style={{ transform: props.isOpen ? 'rotate(-45deg)' : 'rotate(0)' }} />
            </div>
        </>
    )
}

export default Hamburger;