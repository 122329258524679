import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { RegisterDto } from "@/models/RegisterDto";
import { LoginDto } from "@/models/LoginDto";
import { Token } from "@/models/Token";

interface AuthProps {
    authState?: { token: Token | null; authenticated: boolean | null };
    onRegister?: (registerInformation: RegisterDto) => Promise<any>;
    onLogin?: (loginInformation: LoginDto) => Promise<any>;
    onLogout?: () => Promise<any>;
}

const TOKEN_KEY = "jwt_token";
export const API_URL = "https://djecijakuca.azurewebsites.net/";
const AuthContext = createContext<AuthProps>({});

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({ children }: any) => {
    const [authState, setAuthState] = useState<{ token: any | null, authenticated: boolean | null }>({ token: null, authenticated: null });

    const mapTokenToInterface = (rawToken: any): Token => {
        return {
            email: rawToken.email,
            exp: rawToken.exp,
            lastName: rawToken.family_name,
            iat: rawToken.iat,
            id: rawToken.nameid,
            nbf: rawToken.nbf,
            firstName: rawToken.unique_name,
            role: rawToken.Role,
        };
    }

    useEffect(() => {
        const loadToken = async () => {
            const rawToken = localStorage.getItem(TOKEN_KEY);
            if (rawToken) {
                const decodedToken: any = jwtDecode(rawToken!);
                const token: Token = mapTokenToInterface(decodedToken);
                if (decodedToken) {
                    axios.defaults.headers.common["Authorization"] = `Bearer ${rawToken}`;

                    setAuthState({
                        token: token,
                        authenticated: true
                    });
                }
            }
        }
        loadToken();
    }, [])

    const register = async (registerInformation: RegisterDto) => {
        try {
            return await axios.post(`${API_URL}/Auth/Register`, registerInformation);
        } catch (e) {
            return { error: true, msg: (e as any).response.data.message }
        }
    }

    const login = async (loginInformation: LoginDto) => {
        try {
            const result = await axios.post(`${API_URL}/Auth/Login`, loginInformation);

            const decodedToken = jwtDecode(result.data.data);
            const token: Token = mapTokenToInterface(decodedToken);

            setAuthState({
                token: token,
                authenticated: true
            });

            axios.defaults.headers.common['Authorization'] = `bearer ${result.data.data}`;

            localStorage.setItem(TOKEN_KEY, result.data.data);

            return result;
        } catch (e) {
            return { error: true, msg: (e as any).response.data.message }
        }
    };

    const logout = async () => {
        localStorage.removeItem(TOKEN_KEY);
        axios.defaults.headers.common["Authorization"] = "";

        setAuthState({
            token: null,
            authenticated: false
        })
    }
    const value = {
        onRegister: register,
        onLogin: login,
        onLogout: logout,
        authState
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}