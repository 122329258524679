import { Box, Button, Checkbox, FormControlLabel, Modal } from "@mui/material";
import React, { useState } from "react";
import styles from './ChildModal.module.css'
import { deleteReservation } from "../../../api/reservation";
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";

type Props = {
    open: boolean;
    handleClose: () => void;
    reservationId: number | undefined;
    handleDelete: () => void;
}

function ChildModal(props: Props) {
    const [isOccurence, setIsOccurence] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleClose = () => {
        props.handleClose();
    };

    const handleOccurence = () => {
        setIsOccurence(true);
    }

    const handleNotOccurence = () => {
        setIsOccurence(false);
    }

    const handleDelete = async () => {
        if (props.reservationId === undefined) {
            return;
        }
        setIsLoading(true);
        // TODO: Implement DELETE RESERVATION from API
        try {
            var response = await deleteReservation(props.reservationId, isOccurence);
            if (!response.data.success) {
                console.error("Deleting didn't succeed");
            }

            setIsLoading(false);
            props.handleDelete()
            handleClose();
        } catch (error: any) {
            setIsLoading(false);
            console.error("Error while deleting reservation ", error);
        }
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 3,
    };

    return (
        <React.Fragment>
            <Modal
                open={props.open}
                onClose={handleClose}
                aria-labelledby="child-modal-title"
                aria-describedby="child-modal-description"
            >
                <Box sx={{ ...style, width: '50%' }}>
                    <h2 id="child-modal-title">Jeste li sigurni da želite obrisati ovu rezervaciju?</h2>
                    <p id="child-modal-description">
                        Odaberi tip brisanja
                    </p>

                    <FormControlLabel control={<Checkbox checked={isOccurence === false} onChange={handleNotOccurence} />} label="Trenutni" />
                    <FormControlLabel control={<Checkbox checked={isOccurence === true} onChange={handleOccurence} />} label="Svaki" />

                    <div className={styles.buttonContainer}>
                        <Button onClick={handleDelete}>Obriši</Button>
                        <Button onClick={handleClose}>Otkaži</Button>
                    </div>
                    {isLoading && <Bounce color="#4848d5" size={25} />}
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export default ChildModal;