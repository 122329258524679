import { useState } from 'react'
import SubmitButton from '../../components/Button/SubmitButon'
import Input from '../../components/Input/Input'
import styles from './Login.module.css'
import { LoginDto } from '@/models/LoginDto'
import { useAuth } from '../../context/AuthContext'
import { useNavigate } from 'react-router-dom'
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";

export const Login = () => {
    const [user, setUser] = useState<LoginDto>({
        email: "",
        password: ""
    })

    const { onLogin } = useAuth();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const login = async () => {
        setIsLoading(true);
        const result = await onLogin!(user);

        if (result && !result.error) {
            navigate('../reservations')
        }

        setIsLoading(false);
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Prijava</h1>
            <div className={styles.form}>
                <Input value={user.email} setValue={(email: string) =>
                    setUser((prevState) => ({
                        ...prevState,
                        ...{ email: email },
                    }))} type='email' label='Email' />
                <Input value={user.password} setValue={(password: string) =>
                    setUser((prevState) => ({
                        ...prevState,
                        ...{ password: password },
                    }))} type='password' label='Password' />
                <SubmitButton buttonTitle='Prijavi se' onClick={login} />
                {isLoading && <Bounce color='#FFF' size={25} />}
            </div>
        </div>
    )
}