import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import { Reservations } from './pages/reservations/Reservations';
import { Login } from './pages/login/Login';
import Register from './pages/register/Register';
import { AuthProvider } from './context/AuthContext';
import Employees from './pages/employees/Employees';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/reservations",
    element: <Reservations />
  },
  {
    path: "/employees",
    element: <Employees />
  },
  {
    path: "/dk-login",
    element: <Login />
  },
  {
    path: "/dk-register",
    element: <Register />
  }
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);