import axios from 'axios';

const apiUrl = 'https://djecijakuca.azurewebsites.net/';

const axiosClient = axios.create({
    baseURL: apiUrl,
    headers: {
        'Accept': '*/*',
        'Content-Type': 'application/json'
    }
});

axiosClient.interceptors.request.use(config => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
});

export default axiosClient;
