import { useState } from 'react';
import styles from './Carousel.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';


const Carousel = () => {
    const navigate = useNavigate();

    const navigateToReservations = () => {
        navigate('/reservations')
    }

    const [slideCount, setSlideCount] = useState<number>(0);

    const scrollLeft = () => {
        if (slideCount !== 0) {
            setSlideCount(slideCount - 1);
        } else {
            setSlideCount(2);
        }
    }

    const scrollRight = () => {
        if (slideCount !== 2) {
            setSlideCount(slideCount + 1);
        } else {
            setSlideCount(0)
        }
    }

    const handleDotClick = (id: number) => {
        setSlideCount(id);
    };

    const slideShowcase = () => {
        const displayWidth = document.querySelector('.showcase')?.clientWidth;
        const showcaseContent = document.querySelector('.showcaseContent') as HTMLElement | null;

        if (showcaseContent) {
            showcaseContent.style.transform = `translateX(${-slideCount * (displayWidth || 0)}px)`;
        }
    };

    window.addEventListener('resize', slideShowcase);

    const getCurrentSlide = () => {
        if (slideCount === 0) {
            return (
                <div className={`${styles.showcase} ${styles.showcase1}`}>
                    <h1 className={styles.headingText}>Dobrodošli u naš elegantni Lobi - Rezervišite prostor za druženje i networking!</h1>
                    <button type='button' onClick={navigateToReservations}>Rezerviši Lobi</button>
                </div>
            )
        } else if (slideCount === 1) {
            return (
                <div className={`${styles.showcase} ${styles.showcase2}`}>
                    <h1 className={styles.headingText}>Oživite svoj događaj u našem Seminar prostoru - Rezervišite prostor za uspješan skup!</h1>
                    <button type='button' onClick={navigateToReservations}>Saznajte više</button>
                </div>
            )
        } else {
            return (
                <div className={`${styles.showcase} ${styles.showcase3}`}>
                    <h1 className={styles.headingText}>Vaša priča zaslužuje veliki ekran - Rezervišite našu Kino salu za impresivne prezentacije!</h1>
                    <button type='button' onClick={navigateToReservations}>Dostupni termini</button>
                </div>
            )
        }
    }

    return (
        <>
            <div className={styles.showcaseWrapper}>
                <div className={styles.showcaseContent}>
                    <div className={styles.overlay} />
                    {getCurrentSlide()}
                </div>

                <div className={styles.dots}>
                    {[...Array(3)].map((_, index) => (
                        <span key={index} data-id={index} onClick={() => handleDotClick(index)} style={{ color: slideCount === index ? "#FF6525" : "#FFF" }}>
                            <FontAwesomeIcon icon={faCircle} />
                        </span>
                    ))}
                </div>
            </div>
            <div className={styles.leftArrow} onClick={scrollLeft}>
                <span>
                    <FontAwesomeIcon icon={faChevronLeft} />
                </span>
            </div>

            <div className={styles.rightArrow} onClick={scrollRight}>
                <span>
                    <FontAwesomeIcon icon={faChevronRight} />
                </span>
            </div>
        </>
    )
}

export default Carousel;