import './App.css';
import Navbar from './components/Navbar/Navbar';
import { Home } from './pages/home/Home';

function App() {
  return (
    <div>
      <Navbar />
      <Home />
    </div>
  );
}

export default App;
