
import { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './Navbar.module.css';
import logo from '../../assets/logo.png';
import Hamburger from './Hamburger';
import { useAuth } from '../../context/AuthContext';

const Navbar = () => {
    const { authState, onLogout } = useAuth();
    const user = authState?.token;
    const navigate = useNavigate();


    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    const handleLogout = () => {
        onLogout!();
        navigate('../')
    }

    return (
        <>
            <nav className={styles.navbar}>
                <img src={logo} className={styles.logo} alt='Logo' />
                <ul className={styles.navbarOptions}>

                    <div>
                        <NavLink to="/" className={({ isActive }) =>
                            isActive
                                ? `${styles.active} ${styles.option}`
                                : styles.option
                        } >Početna</NavLink>
                        <NavLink to="/reservations" className={({ isActive }) =>
                            isActive
                                ? styles.active
                                : styles.option
                        }>Rezervacije</NavLink>
                        {user?.role === "Admin" && <NavLink to="/employees" className={({ isActive }) =>
                            isActive
                                ? styles.active
                                : styles.option
                        }>Uposlenici</NavLink>}
                    </div>
                    {user && <button className={styles.option} onClick={handleLogout}>Odjavi se</button>}

                </ul>
                <div className={styles.hamburger} onClick={toggleHamburger}>
                    <Hamburger isOpen={hamburgerOpen} />
                </div>
            </nav>
            <div className={styles.mobileNavbar} style={{ display: hamburgerOpen ? 'flex' : 'none' }} >
                <NavLink to="/" className={({ isActive }) =>
                    isActive
                        ? styles.active
                        : styles.option
                }>Početna</NavLink>
                <NavLink to="/reservations" className={({ isActive }) =>
                    isActive
                        ? styles.active
                        : styles.option
                }>Rezervacije</NavLink>
                {user?.role === "Admin" && <NavLink to="/employees" className={({ isActive }) =>
                    isActive
                        ? styles.active
                        : styles.option
                }>Uposlenici</NavLink>}
                {user && <button className={styles.option} onClick={handleLogout}>Odjavi se</button>}
            </div>
        </>
    );
};

export default Navbar;
