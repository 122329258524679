import { useState } from 'react';
import styles from './Input.module.css'

type Props = {
    type: string;
    label: string;
    value: string;
    setValue: (value: string) => void;
}

const Input = (props: Props) => {
    const [inputValue, setInputValue] = useState(props.value);
    const [isDirty, setIsDirty] = useState(false);

    const handleInputChange = (event: any) => {
        const newValue = event.target.value;
        setInputValue(newValue);
        props.setValue(newValue);
        setIsDirty(newValue !== '');
    };

    return (
        <div className={styles.inputGroup}>
            <input required={true} type={props.type} name={props.label} autoComplete="off" className={styles.input} value={inputValue} onChange={handleInputChange} />
            <label className={`${styles.label} ${isDirty ? styles.dirty : ''}`}>{props.label}</label>
        </div>
    )
}

export default Input;