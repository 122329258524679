import styles from './SubmitButton.module.css'

type Props = {
    buttonTitle: string;
    onClick: () => void;
}

const SubmitButton = (props: Props) => {
    return (
        <div>
            <button className={styles.btn} type="submit" onClick={props.onClick}>{props.buttonTitle}</button>
        </div>
    )
}

export default SubmitButton;