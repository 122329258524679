import styles from './HeaderButton.module.css'

type Props = {
    title: string;
    onClick: (title: any) => void;
    isSelected: boolean;
}

const HeaderButton = (props: Props) => {

    const handleClick = () => {
        props.onClick(props.title);
    }

    return (
        <div>
            <button
                onClick={handleClick}
                className={`${styles.button} ${props.isSelected ? styles.selected : ''}`}>{props.title}</button>
        </div>
    )
}

export default HeaderButton;