import styles from './AddButton.module.css'

type Props = {
    text: string;
    onClick: () => void;
}

const AddButton = (props: Props) => {

    const handleClick = () => {
        props.onClick();
    }

    return (
        <button type="button" className={styles.button} onClick={handleClick}>
            <span className={styles.button__text}>{props.text}</span>
            <span className={styles.button__icon}><svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round" stroke="currentColor" height="24" fill="none" className={styles.svg}><line y2="19" y1="5" x2="12" x1="12"></line><line y2="12" y1="12" x2="19" x1="5"></line></svg></span>
        </button>
    )
}

export default AddButton