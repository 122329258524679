import axiosClient from "./axiosClient";

export async function getReservations(hallId, startDate, endDate) {
    return await axiosClient.get('Reservation/GetReservations?hallId=' + hallId + "&startTime=" + startDate + "&endTime=" + endDate);
}

export async function addReservation(reservation) {
    return await axiosClient.post('Reservation/AddReservation', reservation);
}

export async function deleteReservation(reservationId, occurrence) {
    return await axiosClient.delete('Reservation/DeleteReservation?reservationId=' + reservationId + '&occurrence=' + occurrence)
}

export async function getUserById(userId) {
    return await axiosClient.get('Auth/GetUserNameById?userId=' + userId);
}

export async function getUsers() {
    return await axiosClient.get('Auth/GetUsers');
}

export async function toggleEmployeeRole(userId) {
    return await axiosClient.put('Auth/ToggleEmployee?userId=' + userId);
}