import Input from '../../components/Input/Input';
import styles from './Register.module.css'
import SubmitButton from '../../components/Button/SubmitButon';
import { useState } from 'react';
import { RegisterDto } from '@/models/RegisterDto';
import { useAuth } from '../../context/AuthContext';
import { LoginDto } from '@/models/LoginDto';
import { useNavigate } from 'react-router-dom';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";

const Register = () => {
    const { onLogin, onRegister } = useAuth();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [user, setUser] = useState<RegisterDto>({
        email: "",
        firstName: "",
        lastName: "",
        password: ""
    })

    const register = async () => {
        setIsLoading(true);
        const result = await onRegister!(user);
        if (result && result.error) {
            console.error("ERROR")
        } else {
            let loginInfo: LoginDto = {
                email: user.email,
                password: user.password,
            };
            await onLogin!(loginInfo);
            navigate('../reservations')
        }
        setIsLoading(false);
    };

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>Registracija</h1>
            <div className={styles.form}>
                <Input value={user.firstName} setValue={(firstName: string) =>
                    setUser((prevState) => ({
                        ...prevState,
                        ...{ firstName: firstName },
                    }))} type='text' label='Ime' />
                <Input value={user.lastName} setValue={(lastName: string) =>
                    setUser((prevState) => ({
                        ...prevState,
                        ...{ lastName: lastName },
                    }))} type='text' label='Prezime' />
                <Input value={user.email} setValue={(email: string) =>
                    setUser((prevState) => ({
                        ...prevState,
                        ...{ email: email },
                    }))} type='email' label='Email' />
                <Input value={user.password} setValue={(password: string) =>
                    setUser((prevState) => ({
                        ...prevState,
                        ...{ password: password },
                    }))} type='password' label='Password' />
                <SubmitButton buttonTitle='Registruj se' onClick={register} />
                {isLoading && <Bounce color='#FFF' size={25} />}
            </div>
        </div>
    )
}

export default Register;