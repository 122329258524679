import React, { useEffect, useState } from 'react';
import styles from './TimeSlots.module.css'
import { Reservation } from '@/models/Reservation';
import { Box, IconButton, Modal, Snackbar } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faUser, faCalendar, faClock, faBuilding, faUserTie, faEnvelope, faRedo, faClose } from '@fortawesome/free-solid-svg-icons';
import dayjs, { Dayjs } from 'dayjs';
import dayjsRecur from "dayjs-recur";
import { useAuth } from '../../../context/AuthContext';
import { getReservations, getUserById } from '../../../api/reservation';
import ChildModal from '../ChildModal/ChildModal';

dayjs.extend(dayjsRecur);

type Props = {
    newReservation: Reservation;
    currentDate: Dayjs | null;
    selectedType: number;
    handleClose: () => void;
    isFullScreen: boolean;
}

const TimeSlots = (props: Props) => {
    const daysOfWeek = ['Ponedjeljak', 'Utorak', 'Srijeda', 'Četvrtak', 'Petak', 'Subota'];

    const { authState } = useAuth();
    const user = authState?.token;

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("Brisanje nije uspjelo!");

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    }

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
            >
                <FontAwesomeIcon icon={faClose} size='2x' />
            </IconButton>
        </React.Fragment>
    );

    const [selectedReservation, setSelectedReservation] = useState<Reservation | null>(null);
    const [userName, setUserName] = useState<string>("");
    const [open, setOpen] = useState(false);
    const handleOpen = (entry: Reservation) => {
        setSelectedReservation(entry);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const [openChild, setOpenChild] = useState<boolean>(false);

    const handleOpenChild = () => {
        setOpenChild(true);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '@media (max-width: 800px)': {
            width: '90%',
            height: '90%',
            overflow: 'auto'
        },
        width: '40%',
        borderRadius: 10,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const convertToYYYYMMDD = (inputDate: string) => {
        // Parse the input date using dayjs and format it as YYYY-MM-DD
        const formattedDate = dayjs(inputDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
        return formattedDate;
    };

    const getApiRoute = async () => {
        try {
            var dates = getDatesForCurrentWeek();
            var startDate = convertToYYYYMMDD(dates[0]) + "T08:00";
            var endDate = convertToYYYYMMDD(dates[dates.length - 1]) + "T20:00";

            var response = await getReservations(props.selectedType, startDate, endDate);
            if (response.data.success) {
                setEntries(response.data.data);
            }
        } catch (error: any) {
            console.error("Error while getting Reservations ", error)
        }
    }

    useEffect(() => {
        if (props.newReservation.startTime && props.newReservation.endTime && isDuplicateReservation(extractTime(props.newReservation.startTime), extractTime(props.newReservation.endTime))) {
            // Handle duplicate reservation case, e.g., show an error message
            alert("Termin je već rezervisan!")
            return;
        }
        addEntry(props.newReservation);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.newReservation])

    useEffect(() => {
        if (props.currentDate !== null) {
            renderTableHeader();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentDate])

    useEffect(() => {
        if (props.selectedType !== null) {
            getApiRoute();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.selectedType, props.currentDate])

    useEffect(() => {
        if (selectedReservation !== null) {
            getUserName();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedReservation])

    function formatDate(inputString: string) {
        // Parse the input string into a Date object
        const date = new Date(inputString);

        // Extract the date components
        const day = date.getDate();
        const month = date.getMonth() + 1; // Month is zero-based, so we add 1
        const year = date.getFullYear();

        // Pad single-digit day and month with leading zeros
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        // Format the date as DD/MM/YYYY
        const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

        return formattedDate;
    }

    function extractTime(inputString: string) {
        // Parse the input string into a Date object
        const date = new Date(inputString);

        // Extract the time components
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Pad single-digit hours and minutes with leading zeros
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Format the time as HH:MM
        const formattedTime = `${formattedHours}:${formattedMinutes}`;

        return formattedTime;
    }

    const isDuplicateReservation = (startTime: string, endTime: string): boolean => {
        const date = formatDate(startTime);
        return entries.some(entry =>
            formatDate(entry.startTime) === date &&
            ((entry.startTime >= startTime && entry.startTime < endTime) ||
                (entry.endTime > startTime && entry.endTime <= endTime) ||
                (entry.startTime <= startTime && entry.endTime >= endTime))
        );
    };

    const getDatesForCurrentWeek = (): string[] => {
        const currentDay = props.currentDate!.day(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
        const startDate = props.currentDate!.subtract(currentDay === 0 ? 6 : currentDay - 1, 'day');

        const dates = [];
        for (let i = 0; i < 7; i++) {
            const date = startDate.add(i, 'day');
            dates.push(date.format('DD/MM/YYYY'));
        }

        return dates;
    };

    const generateTimeSlots = () => {
        const startTime = 8;
        const endTime = 22;
        const timeSlots = [];

        for (let hour = startTime; hour <= endTime; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                const formattedHour = String(hour).padStart(2, '0');
                const formattedMinute = String(minute).padStart(2, '0');
                timeSlots.push(`${formattedHour}:${formattedMinute}`);
            }
        }

        timeSlots.pop();

        return timeSlots;
    };

    const [entries, setEntries] = useState<Reservation[]>([]);

    const addEntry = (newEntry: Reservation) => {
        setEntries(prevState => [...prevState, newEntry]);
        getApiRoute();
    };

    const renderTableHeader = () => {
        const dates = getDatesForCurrentWeek();

        return (
            <tr>
                <th>Vrijeme</th>
                {daysOfWeek.map((day, index) => (
                    <th key={index}>
                        {day} <br /> {dates[index]}
                    </th>
                ))}
            </tr>
        );
    };

    const isTimeInRange = (time: string, startTime: string, endTime: string) => {
        const [hour, minute] = time.split(':').map(Number);
        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);

        const currentTime = hour * 60 + minute;
        const startTimeValue = startHour * 60 + startMinute;
        const endTimeValue = endHour * 60 + endMinute;

        return currentTime >= startTimeValue && currentTime < endTimeValue;
    };

    const renderTimeSlots = () => {
        const timeSlots = generateTimeSlots();

        return timeSlots.map((time, timeIndex) => {
            const rows = daysOfWeek.map((day, dayIndex) => {
                const entriesForTimeSlot = entries.filter((entry) => {
                    const isSameDate = formatDate(entry.startTime) === getDatesForCurrentWeek()[dayIndex];
                    const isInRange = isTimeInRange(time, extractTime(entry.startTime), extractTime(entry.endTime));

                    return isSameDate && isInRange;
                });

                if (entriesForTimeSlot.length > 0) {
                    const entry = entriesForTimeSlot[0];
                    const numberOfRows = calculateIncrements(extractTime(entry.startTime), extractTime(entry.endTime));

                    // Only render the first cell of the entry
                    if (numberOfRows > 0 && time === extractTime(entry.startTime)) {
                        return (
                            user && user?.role !== "User" ? (
                                <td key={`${dayIndex}-${timeIndex}`} rowSpan={numberOfRows} className={`${props.isFullScreen ? styles.fullscreen : ''} ${styles.multipleRows}`} onClick={() => handleOpen(entry)}>
                                    <div>
                                        {entry.name}
                                    </div>
                                </td>
                            ) : (
                                <td key={`${dayIndex}-${timeIndex}`} rowSpan={numberOfRows} className={`${props.isFullScreen ? styles.fullscreen : ''} ${styles.multipleRows}`}>
                                    <div>
                                    </div>
                                </td>
                            )
                        );
                    }
                } else {
                    return <td className={`${props.isFullScreen ? styles.fullscreen : ''}`} key={`empty-${dayIndex}`} />;
                }

                return null;
            });

            return (
                <tr key={timeIndex}>
                    <td className={`${props.isFullScreen ? styles.fullscreen : ''}`}>{time}</td>
                    {rows}
                </tr>
            );
        });
    };

    const calculateIncrements = (startTime: string, endTime: string): number => {
        // Convert time strings to Date objects
        const startDate = new Date(`2000-01-01T${startTime}:00`);
        const endDate = new Date(`2000-01-01T${endTime}:00`);

        // Calculate the difference in minutes
        const differenceInMinutes = (endDate.getTime() - startDate.getTime()) / (1000 * 60);

        // Calculate the number of 30-minute increments
        const increments = Math.floor(differenceInMinutes / 30);

        return increments;
    };

    const renderTimeSlotsForCurrentDate = () => {
        const timeSlots = generateTimeSlots();
        const currentDate = props.currentDate?.format('DD/MM/YYYY');

        return timeSlots.map((time, timeIndex) => {
            const entriesForTimeSlot = entries.filter(
                (entry) =>
                    formatDate(entry.startTime) === currentDate &&
                    isTimeInRange(time, extractTime(entry.startTime), extractTime(entry.endTime))
            );

            if (entriesForTimeSlot.length > 0) {
                // Find the first entry in the row
                const firstEntry = entriesForTimeSlot[0];

                // Calculate rowspan based on the difference in start and end times
                const rowspan = calculateIncrements(extractTime(firstEntry.startTime), extractTime(firstEntry.endTime));

                // Only render the first time slot in the row
                if (rowspan > 0 && time === extractTime(firstEntry.startTime)) {
                    return (
                        <tr key={`${currentDate}-${timeIndex}`}>
                            <td className={`${props.isFullScreen ? styles.fullscreen : ''}`}>{time}</td>
                            {user ? (
                                <td
                                    rowSpan={rowspan}
                                    colSpan={1}
                                    className={`${props.isFullScreen ? styles.fullscreen : ''} ${styles.multipleRows}`}
                                    onClick={() => handleOpen(firstEntry)}
                                >
                                    <div>{firstEntry.name}</div>
                                </td>
                            ) : (
                                <td
                                    rowSpan={rowspan}
                                    colSpan={1}
                                    className={`${props.isFullScreen ? styles.fullscreen : ''} ${styles.multipleRows}`}
                                >
                                </td>
                            )}

                        </tr>
                    );
                } else {
                    return (
                        <tr key={`empty-${timeIndex}`}>
                            <td className={`${props.isFullScreen ? styles.fullscreen : ''}`}>{time}</td>
                        </tr>
                    )
                }
            } else {
                // Render a normal time slot without reservations
                return (
                    <tr key={`empty-${timeIndex}`}>
                        <td className={`${props.isFullScreen ? styles.fullscreen : ''}`}>{time}</td>
                    </tr>
                );
            }
        });
    };

    const handleDelete = () => {
        if (selectedReservation?.reservationId === undefined) {
            setSnackbarOpen(true);
            return;
        }

        setEntries(prevEntries => prevEntries.filter(entry => entry.reservationId !== selectedReservation.reservationId));
        setSnackbarMessage("Brisanje uspješno!")
        setSnackbarOpen(true);
        handleClose();
    }

    function getHallById(hallId: number | undefined) {
        switch (hallId) {
            case 1:
                return "Kino sala";
            case 2:
                return "Seminar";
            case 3:
                return "Ples";
            case 4:
                return "Lobi";
            case 5:
                return "Kreativna";
            case 6:
                return "Učiona";
        }
    }

    const handleCloseChild = () => {
        setOpenChild(false);
    }

    const getUserName = async () => {
        try {
            var response = await getUserById(selectedReservation?.userId);
            if (response.data.success) {
                setUserName(response.data.data);
            }
        } catch (error: any) {
            console.error("Error while fetching user name");
        }
    }

    return (
        <div>
            <table className={styles.gridView}>
                <thead>{renderTableHeader()}</thead>
                <tbody>{renderTimeSlots()}</tbody>
            </table>

            <table className={styles.mobileView}>
                <thead>
                    <tr>
                        <th>Vrijeme</th>
                        <th>Rezervacije</th>
                    </tr>
                </thead>
                <tbody>{renderTimeSlotsForCurrentDate()}</tbody>
            </table>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className={styles.container}>
                        <div className={styles.headingContainer}>
                            <h1>Detalji rezervacije</h1>
                            {user && user.role === "Admin" ?
                                <FontAwesomeIcon className={styles.icon} icon={faTrashCan} color='red' size='2x' onClick={handleOpenChild} />
                                :
                                <FontAwesomeIcon className={styles.icon} icon={faClose} color='#222' size='2x' onClick={handleClose} />}
                        </div>
                        <div className={styles.modalContainer}>
                            <div className={styles.details}>
                                <FontAwesomeIcon icon={faUser} fontSize={24} />
                                <h2>{selectedReservation?.name}</h2>
                            </div>
                            <div className={styles.details}>
                                <FontAwesomeIcon icon={faCalendar} fontSize={24} />
                                {selectedReservation && <h2>{formatDate(selectedReservation!.startTime)}</h2>}
                            </div>

                            <div className={styles.timeslotWrapper}>
                                <FontAwesomeIcon icon={faClock} fontSize={24} />
                                {selectedReservation && <h2>{extractTime(selectedReservation!.startTime)}</h2>}
                                <h2> - </h2>
                                {selectedReservation && <h2>{extractTime(selectedReservation!.endTime)}</h2>}
                            </div>
                            <div className={styles.details}>
                                <FontAwesomeIcon icon={faBuilding} fontSize={24} />
                                <h2>Sala: {getHallById(selectedReservation?.hallId)}</h2>
                            </div>
                            <div className={styles.details}>
                                <FontAwesomeIcon icon={faUserTie} fontSize={24} />
                                {userName && <h2>Uposlenik: {userName}</h2>}
                            </div>

                            {selectedReservation?.recurrenceType !== 0 && <div className={styles.details}>
                                <FontAwesomeIcon icon={faRedo} fontSize={24} />
                                <h2>{selectedReservation?.recurrenceType === 1 ? "Sedmični termin" : "Mjesečni termin"}</h2>
                            </div>}
                            <div className={`${styles.details} `}>
                                <FontAwesomeIcon icon={faEnvelope} fontSize={24} />
                                <h2>Napomena:</h2>
                            </div>
                            <div className={styles.commentWrapper}>
                                <p>{selectedReservation?.comment}</p>
                            </div>
                            <ChildModal open={openChild} handleClose={handleCloseChild} reservationId={selectedReservation?.reservationId} handleDelete={handleDelete} />
                        </div>
                    </div>
                </Box>
            </Modal>

            <Snackbar
                open={snackbarOpen}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
                action={action}
            />
        </div>
    );
};

export default TimeSlots;
