import React from 'react';
import styles from './Footer.module.css';
import logo from '../../assets/logo.png';
import hubLogo from '../../assets/hub-logo.png';

const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.logoWrapper}>
                <img src={logo} className={styles.logo} alt='Logo' />
                <h1>X</h1>
                <img src={hubLogo} className={styles.hubLogo} alt='Logo' />
            </div>
            <div className={styles.contact}>
                <h2>Kontakt podaci</h2>
                <p>Adresa: Grbavička bb</p>
                <p>Općina: Novo Sarajevo</p>
                <p>Tel: +387 (0)33 811 010</p>
            </div>
            <div className={styles.appInfo}>
                <p>Made with &#x2665; by <a target='blank' href='https://www.linkedin.com/in/ibrahim-efendi%C4%87-38a478224/'>Ibrahim</a> & <a target='blank' href='https://www.linkedin.com/in/benjaminhodzic1/'>Benjamin</a></p>
                <p>&copy; {new Date().getFullYear()} All rights reserved</p>
            </div>
        </footer>
    );
};

export default Footer;
