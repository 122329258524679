import React, { useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import styles from './Reservations.module.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import AddButton from '../../components/Button/AddButton';
import CalendarView from '../../components/CalendarView/CalendarView';
import Modal from '@mui/material/Modal';
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { Reservation } from '@/models/Reservation';
import Footer from '../../components/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../context/AuthContext';
import { addReservation } from '../../api/reservation';
import { ReservationRequest } from '@/models/ReservationRequest';
import emailjs from '@emailjs/browser';
import Bounce from "react-activity/dist/Bounce";
import "react-activity/dist/Bounce.css";

enum RecurringType {
    None = 0,
    Weekly = 1,
    Monthly = 2
}

export const Reservations = () => {
    const { authState } = useAuth();
    var user = authState?.token;;

    const [currentDate, setCurrentDate] = useState<Dayjs | null>(dayjs());

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("Forma nije validna!");

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [isFullScreen, setIsFullScreen] = useState(false);
    const handleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    }

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [selectedType, setSelectedType] = useState("1");
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());

    const [recurringEvent, setRecurringEvent] = useState<RecurringType>(RecurringType.None);

    const [newReservation, setNewReservation] = useState<Reservation>({
        name: "",
        startTime: "",
        endTime: "",
        comment: "",
        userId: 0,
        hallId: 1,
        recurrenceType: recurringEvent as number
    })

    const [newReservationData, setNewReservationData] = useState<Reservation>({
        name: "",
        startTime: "",
        endTime: "",
        comment: "",
        userId: 0,
        hallId: 0,
        recurrenceType: recurringEvent as number
    })

    const [reservationRequest, setReservationRequest] = useState<ReservationRequest>({
        name: "",
        idNumber: "",
        numberOfParticipants: "",
        technicalGear: "",
        email: "",
        phoneNumber: "",
        description: "",
        type: "Fizičko Lice",
        date: dayjs().format("DD/MM/YYYY"),
        startTime: "",
        endTime: "",
        hallName: "Kino sala",
    });

    const [selectedRequestDate, setSelectedRequestDate] = useState<Dayjs | null>(dayjs());
    const [selectedTypeRequest, setSelectedTypeRequest] = useState<string>("Kino sala");

    const [personType, setPersonType] = useState<"Pravno Lice" | "Fizičko Lice">("Fizičko Lice");

    const [openRequestModal, setOpenRequestModal] = useState(false);
    const handleOpenRequestModal = () => setOpenRequestModal(true);
    const handleCloseRequestModal = () => setOpenRequestModal(false);

    function extractTime(inputString: string) {
        // Parse the input string into a Date object
        const date = new Date(inputString);

        // Extract the time components
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Pad single-digit hours and minutes with leading zeros
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        // Format the time as HH:MM
        const formattedTime = `${formattedHours}:${formattedMinutes}`;

        return formattedTime;
    }

    const isTimeValid = (time: string): boolean => {
        const regex = /^(0[8-9]|1[0-9]|20|21|22):(00|30)$/;
        return regex.test(time);
    };

    const isHourInRange = (hour: number, minutes?: number): boolean => {
        if (hour === 22) {
            return minutes === 0;
        }
        return hour >= 8 && hour <= 22;
    };

    const validateRequest = (request: ReservationRequest) => {
        const exemptedFields = ['idNumber'];

        const allFieldsEmpty = Object.entries(request).every(([key, field]) => {
            if (request.type === "Fizičko Lice" && exemptedFields.includes(key)) {
                return true; // Skip the empty check for exempted fields when type is "Fizičko Lice"
            }
            return field !== '';
        });

        if (!allFieldsEmpty) {
            setSnackbarMessage("Niste popunili sva polja!");
            return false;
        }

        const startTime = (request.startTime).split(':');
        const endTime = (request.endTime).split(':');

        const startHour = parseInt(startTime[0]);
        const startMinutes = parseInt(startTime[1]);
        const endHour = parseInt(endTime[0]);
        const endMinutes = parseInt(endTime[1]);

        if (startHour === 22 || (startHour === endHour && startMinutes === endMinutes)) {
            setSnackbarMessage("Pogrešan vremenski interval!")
            return false;
        }

        if (!isHourInRange(startHour) || !isHourInRange(endHour, endMinutes) || !isTimeValid((request.startTime)) || !isTimeValid((request.endTime))) {
            setSnackbarMessage("Vremenski interval nije validan!")
            return false;
        }

        return true;
    }
    const validateReservation = (reservation: Reservation) => {
        const allFieldsEmpty = Object.values(reservation).every(field => field === '');

        if (allFieldsEmpty) {
            setSnackbarMessage("Niste popunili sva polja!");
            return false;
        }

        const startTime = extractTime(reservation.startTime).split(':');
        const endTime = extractTime(reservation.endTime).split(':');

        const startHour = parseInt(startTime[0]);
        const startMinutes = parseInt(startTime[1]);
        const endHour = parseInt(endTime[0]);
        const endMinutes = parseInt(endTime[1]);

        if (startHour === 22 || (startHour === endHour && startMinutes === endMinutes)) {
            setSnackbarMessage("Pogrešan vremenski interval!")
            return false;
        }

        if (!isHourInRange(startHour) || !isHourInRange(endHour, endMinutes) || !isTimeValid(extractTime(reservation.startTime)) || !isTimeValid(extractTime(reservation.endTime))) {
            setSnackbarMessage("Vremenski interval nije validan!")
            return false;
        }

        return true;
    }

    const getFormattedDateTime = (date: Dayjs | null, time: string) => {
        if (date) {
            const formattedDate = (date).format('YYYY-MM-DD');
            return `${formattedDate}T${time}`;
        }
        return null;
    };

    const addNewReservation = async () => {
        setIsLoading(true);
        const startDateTime = getFormattedDateTime(selectedDate, newReservation.startTime);
        const endDateTime = getFormattedDateTime(selectedDate, newReservation.endTime);

        if (!startDateTime || !endDateTime) {
            setSnackbarMessage("Vremenski intervali nisu u dobrom formatu!")
            setSnackbarOpen(true);
            return;
        }

        var reservation: Reservation = {
            userId: +user!.id,
            hallId: newReservation.hallId,
            name: newReservation.name,
            recurrenceType: newReservation.recurrenceType,
            comment: newReservation.comment,
            startTime: startDateTime,
            endTime: endDateTime,
        }

        if (!validateReservation(reservation)) {
            setSnackbarOpen(true);
            return;
        }
        try {
            const response = await addReservation(reservation);
            if (!response.data.success) {
                setSnackbarMessage("Rezervacija nije uspješno dodana!")
            }
            else {
                if (response.data.message.includes("Preklapanje")) {
                    setSnackbarMessage(response.data.message);
                }
                else {
                    setSnackbarMessage("Rezervacija dodana!")
                }
                handleClose();
            }

            setSnackbarOpen(true);
            setIsLoading(false);
        } catch (error: any) {
            setSnackbarMessage(error.response.data.message);
            setSnackbarOpen(true);
            setIsLoading(false);
        }
        setNewReservationData(newReservation);
    }

    const sendReservationRequest = () => {
        if (!validateRequest(reservationRequest)) {
            setSnackbarOpen(true);
            return;
        }

        emailjs.send("service_1zyfkoi", "template_b2sroxo", {
            user_name: reservationRequest.name,
            id_number: reservationRequest.idNumber,
            date: reservationRequest.date,
            start_time: reservationRequest.startTime,
            end_time: reservationRequest.endTime,
            type: reservationRequest.hallName,
            description: reservationRequest.description,
            number_people: reservationRequest.numberOfParticipants,
            technical_gear: reservationRequest.technicalGear,
            email: reservationRequest.email,
            phone: reservationRequest.phoneNumber,
        }, "5ypVgMqiq7y9TYudZ").then((res) => {
            setSnackbarMessage("Uspješno ste poslali zahtjev!");
            setSnackbarOpen(true);
            handleCloseRequestModal();
        }).catch((error) => {
            setSnackbarMessage("Zahtjev nije uspješno poslan!");
            setSnackbarOpen(true);
            handleCloseRequestModal();
        });
    }

    const handleChange = (event: SelectChangeEvent) => {
        setNewReservation(prevData => ({ ...prevData, hallId: +event.target.value }))
        setSelectedType(event.target.value);
    };

    const handleRequestTypeChange = (event: SelectChangeEvent) => {
        setReservationRequest(prevData => ({ ...prevData, hallName: event.target.value }))
        setSelectedTypeRequest(event.target.value);
    };

    const handleDateChange = (selectedDate: Dayjs | null) => {
        setSelectedDate(selectedDate);
    };

    const handleRequestDateChange = (selectedDate: Dayjs | null) => {
        setSelectedRequestDate(selectedDate);
    };

    const handleCurrentDate = (selectedDate: Dayjs | null) => {
        setCurrentDate(selectedDate);
    }

    const handleFizickoLice = () => {
        setPersonType("Fizičko Lice");
        setReservationRequest(prevData => ({ ...prevData, type: "Fizičko Lice" }))
    }

    const handlePravnoLice = () => {
        setPersonType("Pravno Lice");
        setReservationRequest(prevData => ({ ...prevData, type: "Pravno Lice" }))
    }

    const handleMonthly = () => {
        var newState = recurringEvent === RecurringType.Monthly ? RecurringType.None : RecurringType.Monthly;
        setRecurringEvent(newState);
        setNewReservation(prevData => ({ ...prevData, recurrenceType: newState }))
    }

    const handleWeekly = () => {
        var newState = recurringEvent === RecurringType.Weekly ? RecurringType.None : RecurringType.Weekly;
        setRecurringEvent(newState);
        setNewReservation(prevData => ({ ...prevData, recurrenceType: newState }))
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    }

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '@media (max-width: 800px)': {
            width: '90%',
            height: '90%',
            overflow: 'auto'
        },
        width: '40%',
        borderRadius: 10,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const styleRequest = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        '@media (max-width: 800px)': {
            width: '90%',
            height: '90%',
            overflow: 'auto'
        },
        width: '40%',
        height: '90%',
        overflow: 'auto',
        borderRadius: 10,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseSnackbar}
            >
                <FontAwesomeIcon icon={faClose} size='2x' />
            </IconButton>
        </React.Fragment>
    );

    return (
        isFullScreen ? <CalendarView isFullScreen={true} handleFullScreen={handleFullScreen} handleClose={() => { }} currentDate={currentDate} newReservation={newReservationData} /> : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Navbar />
                <div className={styles.container}>
                    <div className={styles.header}>
                        <div className={styles.rightSide}>
                            <DatePicker label="Odaberi datum" value={currentDate} onChange={handleCurrentDate} format='DD/MM/YYYY' />
                            {user?.role === "Admin" ? <AddButton text='Dodaj rezervaciju' onClick={handleOpen} /> : <AddButton text='Pošalji zahtjev' onClick={handleOpenRequestModal} />}
                        </div>
                        <div className={styles.rightSide}>

                        </div>
                    </div>
                    <CalendarView
                        isFullScreen={false}
                        handleFullScreen={handleFullScreen}
                        handleClose={handleClose}
                        newReservation={newReservationData}
                        currentDate={currentDate} />
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <div className={styles.modalContainer}>
                                <div className={styles.headingContainer}>
                                    <h1>Dodaj novu rezervaciju</h1>
                                    <FontAwesomeIcon icon={faClose} color='#222' size='2x' onClick={handleClose} className={styles.closeIcon} />
                                </div>
                                <div className={styles.form}>

                                    <FormControl fullWidth>
                                        <TextField id="ime" label="Ime i prezime" variant="outlined" sx={{ borderColor: 'background.paper' }}
                                            onChange={(event) => setNewReservation(prevData => ({ ...prevData, name: event.target.value }))} />
                                    </FormControl>
                                    <FormControl>
                                        <DatePicker format='DD/MM/YYYY'
                                            value={selectedDate} onChange={handleDateChange}
                                        />
                                    </FormControl>
                                    <div className={styles.timeslotWrapper}>
                                        <TextField id="pocetak" label="Početak" variant="outlined" placeholder='09:00'
                                            onChange={(event) => setNewReservation(prevData => ({ ...prevData, startTime: event.target.value }))}
                                        />
                                        <TextField id="kraj" label="Kraj" variant="outlined" placeholder='10:00'
                                            onChange={(event) => setNewReservation(prevData => ({ ...prevData, endTime: event.target.value }))}
                                        />
                                    </div>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Sala</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedType}
                                            label="Sala"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={"1"}>Kino sala</MenuItem>
                                            <MenuItem value={"2"}>Seminar</MenuItem>
                                            <MenuItem value={"3"}>Ples</MenuItem>
                                            <MenuItem value={"4"}>Lobi</MenuItem>
                                            <MenuItem value={"5"}>Kreativna</MenuItem>
                                            <MenuItem value={"6"}>Učiona</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <TextField variant="outlined" label="Napomene" multiline
                                            onChange={(event) => setNewReservation(prevData => ({ ...prevData, comment: event.target.value }))}
                                        />
                                    </FormControl>

                                    <FormControlLabel control={<Checkbox checked={recurringEvent === RecurringType.Monthly} onChange={handleMonthly} />} label="Mjesečni termin" />
                                    <FormControlLabel control={<Checkbox checked={recurringEvent === RecurringType.Weekly} onChange={handleWeekly} />} label="Sedmični termin" />

                                    <Button variant='contained' fullWidth onClick={addNewReservation} sx={{ background: '#4848d5', height: 64 }}>Dodaj</Button>
                                    <div style={{ justifyContent: "center", alignItems: "center" }}>
                                        {isLoading && <Bounce color='#4848d5' size={25} />}
                                    </div>
                                </div>
                            </div>
                        </Box>
                    </Modal>

                    <Modal
                        open={openRequestModal}
                        onClose={handleCloseRequestModal}
                        aria-labelledby="modal-modal-title-request"
                        aria-describedby="modal-modal-description-request"
                    >
                        <Box sx={styleRequest} className={styles.scrollBox}>
                            <div className={styles.modalContainer}>
                                <div className={styles.headingContainer}>
                                    <h1>Pošalji zahtjev za rezervaciju</h1>
                                    <FontAwesomeIcon icon={faClose} color='#222' size='2x' onClick={handleCloseRequestModal} className={styles.closeIcon} />
                                </div>
                                <div className={styles.form}>

                                    <FormControl fullWidth>
                                        <TextField name='user_name' id="ime" label={personType === "Fizičko Lice" ? "Ime i prezime" : "Naziv"} variant="outlined" sx={{ borderColor: 'background.paper' }}
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, name: event.target.value }))} />
                                    </FormControl>

                                    <FormControlLabel control={<Checkbox checked={personType === "Fizičko Lice"} onChange={handleFizickoLice} />} label="Fizičko lice" />
                                    <FormControlLabel control={<Checkbox checked={personType === "Pravno Lice"} onChange={handlePravnoLice} />} label="Pravno lice" />

                                    {personType === "Pravno Lice" && <FormControl fullWidth>
                                        <TextField name='id_number' id="ime" label="ID broj" variant="outlined" sx={{ borderColor: 'background.paper' }}
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, idNumber: event.target.value }))} />
                                    </FormControl>}

                                    <FormControl>
                                        <DatePicker name='date' format='DD/MM/YYYY'
                                            value={selectedRequestDate} onChange={handleRequestDateChange}
                                        />
                                    </FormControl>
                                    <div className={styles.timeslotWrapper}>
                                        <TextField name='start_time' id="pocetak" label="Početak" variant="outlined" placeholder='09:00'
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, startTime: event.target.value }))}
                                        />
                                        <TextField name='end_time' id="kraj" label="Kraj" variant="outlined" placeholder='10:00'
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, endTime: event.target.value }))}
                                        />
                                    </div>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Sala</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedTypeRequest}
                                            name='type'
                                            label="Sala"
                                            onChange={handleRequestTypeChange}
                                        >
                                            <MenuItem value={"Kino sala"}>Kino sala</MenuItem>
                                            <MenuItem value={"Seminar"}>Seminar</MenuItem>
                                            <MenuItem value={"Ples"}>Ples</MenuItem>
                                            <MenuItem value={"Lobi"}>Lobi</MenuItem>
                                            <MenuItem value={"Kreativna"}>Kreativna</MenuItem>
                                            <MenuItem value={"Učiona"}>Učiona</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <TextField name='description' variant="outlined" label="Opis događaja" multiline
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, description: event.target.value }))}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <TextField name='number_people' variant="outlined" label="Broj ljudi"
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, numberOfParticipants: event.target.value }))}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <TextField name='technical_gear' variant="outlined" label="Tehnička oprema"
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, technicalGear: event.target.value }))}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <TextField name='email' variant="outlined" label="Email"
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, email: event.target.value }))}
                                        />
                                    </FormControl>

                                    <FormControl fullWidth>
                                        <TextField name='phone' variant="outlined" label="Broj telefona"
                                            onChange={(event) => setReservationRequest(prevData => ({ ...prevData, phoneNumber: event.target.value }))}
                                        />
                                    </FormControl>

                                    <Button type='submit' variant='contained' fullWidth onClick={sendReservationRequest} sx={{ background: '#4848d5', height: 64 }}>Pošalji</Button>
                                </div>
                            </div>
                        </Box>
                    </Modal>
                    <Snackbar
                        open={snackbarOpen}
                        onClose={handleCloseSnackbar}
                        message={snackbarMessage}
                        action={action}
                    />
                </div>
                <Footer />
            </LocalizationProvider>)

    )
}