import { useEffect, useState } from 'react';
import styles from './Employees.module.css'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { User } from '@/models/User';
import { getUsers, toggleEmployeeRole } from '../../api/reservation';
import Navbar from '../../components/Navbar/Navbar';

const Employees = () => {
    const [data, setData] = useState<User[]>([]);

    useEffect(() => {
        fetchEmployees();
        // eslint-disable-next-line
    }, [])

    const toggleEmployee = async (userId: number | undefined) => {
        if (userId === undefined) {
            return;
        }

        try {
            const response = await toggleEmployeeRole(userId);
            if (response.data.success) {
                var newUser: User = response.data.data;
                const updatedUsers = data.map((user) => user.id === userId ? { ...user, role: newUser.role } : user);

                setData(updatedUsers);
            }
        } catch (error: any) {
            console.error("Greska prilikom mijenjanja nivoa pristupa!");
        }
    }

    const fetchEmployees = async () => {
        try {
            const response = await getUsers();
            if (response.data.success) {
                setData(response.data.data);
            }
        } catch (error: any) {
            console.error("Failed to get employees!");
        }
    }

    return (
        <>
            <Navbar />
            <TableContainer component={Paper} className={styles.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {/* Add existing table headers here */}
                            <TableCell>Ime i prezime</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Nivo pristupa</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((user, index) => (
                            <TableRow key={index}>
                                <TableCell>{user.firstName + " " + user.lastName}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.role}</TableCell>
                                <TableCell style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button disabled={user.role === 0} style={{ minWidth: '50%' }} variant="contained" color={user.role === 2 ? "primary" : "error"} onClick={() => toggleEmployee(user?.id)}>
                                        {user.role === 2 ? "Odobri" : "Ukloni"}
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default Employees;