import { useState } from 'react';
import HeaderButton from '../Button/HeaderButton';
import styles from './CalendarView.module.css'
import TimeSlots from './TimeSlots/TimeSlots';
import { Reservation } from '@/models/Reservation';
import { Dayjs } from 'dayjs';
import { IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompress, faExpand } from '@fortawesome/free-solid-svg-icons';

type Props = {
    newReservation: Reservation;
    currentDate: Dayjs | null;
    handleClose: () => void;
    isFullScreen: boolean;
    handleFullScreen: () => void;
}

export const CalendarView = (props: Props) => {
    const buttons = ["Kino sala", "Seminar", "Ples", "Lobi", "Kreativna", "Uciona"]
    const [selectedType, setSelectedType] = useState<number>(1);

    const handleTypeSelected = (title: string) => {
        var index = buttons.findIndex(x => x === title);
        setSelectedType(index + 1);
    }

    return (
        <div className={`${props.isFullScreen ? '' : styles.container}`}>
            <div className={styles.header}>
                <div className={styles.leftSide}>
                    {buttons.map((button, index) => (
                        <HeaderButton
                            key={button + index}
                            title={button}
                            onClick={handleTypeSelected}
                            isSelected={selectedType - 1 === index}
                        />
                    ))}
                </div>
                <div className={styles.fullScreenButton}>
                    <IconButton
                        size="medium"
                        aria-label="fullscreen"
                        color="inherit"
                    >
                        <FontAwesomeIcon icon={props.isFullScreen ? faCompress : faExpand} size='2x' onClick={props.handleFullScreen} />
                    </IconButton>
                </div>
            </div>
            <div className={`${props.isFullScreen ? styles.calendarFull : styles.calendar}`}>
                <TimeSlots isFullScreen={props.isFullScreen} selectedType={selectedType} handleClose={props.handleClose} newReservation={props.newReservation} currentDate={props.currentDate} />
            </div>
        </div>
    )
}

export default CalendarView;